import { template as template_c66c87042e4c49afb59343a158a25aba } from "@ember/template-compiler";
const WelcomeHeader = template_c66c87042e4c49afb59343a158a25aba(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
