import { template as template_45906bc8e47f48c7b082d80a73b419a1 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_45906bc8e47f48c7b082d80a73b419a1(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
