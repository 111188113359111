import { template as template_95a42e69e1904318a111838ad9ff4585 } from "@ember/template-compiler";
const FKLabel = template_95a42e69e1904318a111838ad9ff4585(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
